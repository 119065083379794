import "./App.css";
import RouteConfig from "./RouteConfig";

function App() {
  return (
    <div className="page main-page" size="A4">
      <div className="px-5 py-5 ">
        <RouteConfig />
      </div>
      <footer className="">
        <div className="footer-bg">
          <div className="container  d-flex justify-content-around align-items-center">
            <p>(92)3417019719, (92)537530465</p>
            <p>contact@redxsofts.com</p>
            <p>www.redxsofts.com</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
